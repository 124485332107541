import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Link from "gatsby-link";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";

function Harness({data}) {
    return (
        <section className=" p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={5} xl={5} xxl={5}>
                        <div className="example" data-text={data?.harness_body_txt}>
                            <h2>{HtmlParser(checkData(data, 'harness_title'))}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="offset-lg-1">
                        <div>{HtmlParser(checkData(data, 'harness_description'))}</div>
                        <Link to={checkData(data, 'harness_btn_url')}
                              className="border_btn">{checkData(data, 'harness_btn_txt')}</Link>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Harness;